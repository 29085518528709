<template>
  <div>
    <p class="container" style="text-align: center">
      Bienvenido al portal de la CVC donde podrás registrar tus solicitudes
      sobre el trámite de su interés
    </p>
    <p class="container" style="font-weight: bold; text-align: center">
      DERECHOS AMBIENTALES
    </p>
    <div class="container" id="DA">
      <!-- Inicio Acordeon -->
      <div class="accordion" role="tablist">
        <!-- CONCESIÓN DE AGUAS SUPERFICIALES -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              variant="info"
              style="background: #45b39d"
              >CONCESIÓN DE AGUAS SUPERFICIALES</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Derecho que adquiere una persona natural o jurídica,
                        pública o privada, para aprovechar las aguas en un uso o
                        fin determinado. Se otorga mediante acto administrativo
                        que define el caudal de aprovechamiento y el régimen de
                        operación.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Superficiales"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional y
                        Formulario PUEAA.
                        <br />
                        <b-dropdown
                          variant="outline-primary"
                          text="Formularios"
                        >
                          <b-dropdown-item
                            @click="
                              abrirNuevoTab(rutas.rutaformatoSuperficiales)
                            "
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            FUN</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutapueaacomun)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            PUEAA Común</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutapueaasimplificado)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            PUEAA Simplificado</b-dropdown-item
                          > <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutacircularpueaa)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            Circular 06 PUEAA</b-dropdown-item
                          > <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutaorientacionpueaa)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            Orientaciones PUEAA</b-dropdown-item
                          > </b-dropdown
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaAnexoSuperficales)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- CONCESIÓN DE AGUAS SUBTERRÁNEAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-2
              variant="info"
              style="background: #45b39d"
              >CONCESIÓN DE AGUAS SUBTERRÁNEAS</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (12)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el modo de adquirir el derecho a usar o aprovechar
                        las aguas subterráneas, tanto en predios propios como
                        ajenos.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Subterraneas"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional y
                        Formulario PUEAA.
                        <br />
                        <b-dropdown
                          variant="outline-primary"
                          text="Formularios"
                        >
                          <b-dropdown-item
                            @click="
                              abrirNuevoTab(rutas.rutaformatoSubterraneas)
                            "
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            FUN</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutapueaacomun)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            PUEAA Común</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutapueaasimplificado)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            PUEAA Simplificado</b-dropdown-item
                          > <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutacircularpueaa)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            Circular 06 PUEAA</b-dropdown-item
                          > <b-dropdown-item
                            @click="abrirNuevoTab(rutas.rutaorientacionpueaa)"
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            Orientaciones PUEAA</b-dropdown-item
                          ></b-dropdown
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaAnexoSubterraneas)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PERMISO DE VERTIMIENTO A CUERPOS DE AGUA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-3
              variant="info"
              style="background: #45b39d"
              >PERMISO DE VERTIMIENTO A CUERPOS DE AGUA</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Vertimientos_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        El permiso de vertimiento es el que concede la CVC,
                        mediante acto administrativo, a toda persona natural o
                        jurídica cuya actividad o servicio genere vertimientos a
                        las aguas superficiales, marinas, o al suelo.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/vertimiento_agua"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="
                            abrirNuevoTab(rutas.rutaformatoVertimientoagua)
                          "
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaAnexoVertimientoagua)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PERMISO DE VERTIMIENTO AL SUELO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-4
              variant="info"
              style="background: #45b39d"
              >PERMISO DE VERTIMIENTO AL SUELO</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Vertimientos_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        El permiso de vertimiento es el que concede la CVC,
                        mediante acto administrativo, a toda persona natural o
                        jurídica cuya actividad o servicio genere vertimientos a
                        las aguas superficiales, marinas, o al suelo.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/vertimiento_suelo"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="
                            abrirNuevoTab(rutas.rutaformatoVertimientosuelo)
                          "
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="
                            abrirNuevoTab(rutas.rutaAnexoVertimientosuelo)
                          "
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ADECUACIÓN DE TERRENOS PARA ESTABLECIMIENTOS DE CULTIVOS, PASTOS Y BOSQUES -->
        <!-- <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-5
              variant="info"
              style="background: #45b39d"
              >ADECUACIÓN DE TERRENOS PARA ESTABLECIMIENTOS DE CULTIVOS, PASTOS
              Y BOSQUES</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (2)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso que otorga la CVC, a quien pretenda
                        realizar actividades de adecuación de terrenos con el
                        objeto de establecer cultivos, pastos o bosques, y sea
                        necesario erradicar vegetación arbórea incluyendo
                        rastrojos altos, en diferentes estados de desarrollo.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/AdecuacionTerreno"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="
                            abrirNuevoTab(rutas.rutaformatoAdecuacionterrenos)
                          "
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- APROVECHAMIENTO FORESTAL PERSISTENTE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-6
              variant="info"
              style="background: #45b39d"
              >APROVECHAMIENTO FORESTAL PERSISTENTE</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Aprovechamiento Forestal.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso o autorización que otorga la CVC,
                        concediendo el derecho a aprovechar bosques naturales
                        ubicados en terrenos de dominio público o privado,
                        respectivamente.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Aprovechamientofp"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- APROVECHAMIENTO FORESTAL UNICO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-7
              variant="info"
              style="background: #45b39d"
              >APROVECHAMIENTO FORESTAL ÚNICO</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Aprovechamiento Forestal.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso o autorización que otorga la CVC,
                        concediendo el derecho a aprovechar bosques naturales
                        ubicados en terrenos de dominio público o privado,
                        respectivamente.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Aprovechamientofu"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- AUTORIZACION PARA OCUPACION DE CAUCE Y OBRAS HIDRAULICAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-8
              variant="info"
              style="background: #45b39d"
              >AUTORIZACIÓN PARA OCUPACIÓN DE CAUCE Y OBRAS
              HIDRÁULICAS</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Ocupación de Cauce_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es la construcción de obras que ocupen el cauce de una
                        corriente o depósito de agua, así como para la ocupación
                        permanente o transitoria de playas, así como la
                        aprobación de los diseños de las obras necesarias para
                        captar, controlar, conducir, almacenar o distribuir el
                        caudal de una corriente hídrica.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Autorizacionocoh"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="
                            abrirNuevoTab(rutas.rutaformatoOcupacioncause)
                          "
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- AUTORIZACION PARA VIAS CARRETEABLES Y EXPLANACIONES-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-9
              variant="info"
              style="background: #45b39d"
              >AUTORIZACIÓN PARA VÍAS CARRETEABLES Y EXPLANACIONES</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Vías Carreteables y Explanaciones_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es la autorización que otorga la CVC, para la
                        construcción de vías, carreteables y explanaciones en
                        predios de propiedad privada en el área rural.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Autorizacionvce"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="
                            abrirNuevoTab(rutas.rutaformatoViascarreteables)
                          "
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DIAGNOSTICO AMBIENTAL DE ALTERNATIVAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-10
              variant="info"
              style="background: #45b39d"
              >DIAGNOSTICO AMBIENTAL DE ALTERNATIVAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-10"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (3)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Trámite para evaluar y comparar las diferentes opciones
                        que presente el peticionario, bajo las cuales es posible
                        desarrollar un proyecto, obra o actividad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/DiagnosticoAmbAlt"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- EXPEDICIÓN DE CERTIFICADO DE EXPORTACIÓN DE ESPECIES DE FLORA NO CITES 
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-11
              variant="info"
              style="background: #45b39d"
              >EXPEDICIÓN DE CERTIFICADO DE EXPORTACIÓN DE ESPECIES DE FLORA NO
              CITES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-11"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (4)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es la certificación que se otorga para la exportación de
                        productos forestales en segundo grado de transformación
                        o flor cortada, follaje y demás productos de la flora
                        silvestre no obtenidos mediante aprovechamiento del
                        medio natural No Cites.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Expedicionceefc"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- MODIFICACIÓN DE LICENCIA AMBIENTAL O PLAN DE MANEJO AMBIENTAL -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-12
              variant="info"
              style="background: #45b39d"
              >MODIFICACIÓN DE LICENCIA AMBIENTAL O PLAN DE MANEJO
              AMBIENTAL</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-12"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Licencimiento Ambiental_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Trámite para determinar la viabilidad ambiental de las
                        modificaciones a un proyecto, obra o actividad a la cual
                        se otorgó Licencia Ambiental o se estableció un Plan de
                        Manejo Ambiental.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Modificacionlapma"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- OTORGAMIENTO DE LICENCIA AMBIENTAL -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-13
              variant="info"
              style="background: #45b39d"
              >OTORGAMIENTO DE LICENCIA AMBIENTAL</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-13"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Licencimiento Ambiental_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es la autorización para la ejecución de un proyecto,
                        obra o actividad que, de acuerdo con la ley y los
                        reglamentos, pueda producir deterioro grave a los
                        recursos naturales renovables o al medio ambiente o
                        introducir modificaciones considerables o notorias al
                        paisaje.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Otorgamientola"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaOtorgamiento)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- OTORGAMIENTO DE LICENCIA DE FUNCIONAMIENTO PARA ZOOLOGICOS Y PABELLONES DE FLORA SILVESTRE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-14
              variant="info"
              style="background: #45b39d"
              >OTORGAMIENTO DE LICENCIA DE FUNCIONAMIENTO PARA ZOOLÓGICOS Y
              PABELLONES DE FLORA SILVESTRE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-14"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Establecimiento Zoológicos_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Propiedad pública o privada, en donde se mantienen
                        individuos de fauna silvestre en confinamiento o
                        semiconfinamiento para exhibición y con propósitos
                        educativos y en el cual se adelantan investigaciones
                        biológicas sobre las especies en cautividad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Otorgamientolzoo"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- OTORGAMIENTO DE PERMISO DE EMISIONES ATMOSFÉRICAS PARA FUENTES FIJAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-15
              variant="info"
              style="background: #45b39d"
              >OTORGAMIENTO DE PERMISO DE EMISIONES ATMOSFÉRICAS PARA FUENTES
              FIJAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-15"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Emisiones Atmosféricas_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        El permiso de emisión atmosférica es el que concede la
                        CVC, mediante acto administrativo, para que una persona
                        natural o jurídica, pública o privada propietaria de la
                        obra, empresa, actividad, industria o establecimiento
                        que origina las emisiones, pueda realizar emisiones al
                        aire.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Otorgamientopeaff"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="
                            abrirNuevoTab(rutas.rutaformatoemisionesafmos)
                          "
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PERMISO O AUTORIZACIÓN PARA EL APROVECHAMIENTO DE PDTOS DE FLORA Y EJEMPLARES O PDTOS DE FAUNA SILVESTRE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-16
              variant="info"
              style="background: #45b39d"
              >PERMISO O AUTORIZACIÓN PARA EL APROVECHAMIENTO DE PDTOS DE FLORA
              Y EJEMPLARES O PDTOS DE FAUNA SILVESTRE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-16"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso o autorización para el aprovechamiento de
                        productos de la flora y de ejemplares o productos de la
                        fauna silvestre.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button href="/Permisoaffs" variant="outline-success">
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PERMISO PARA EL APROVECHAMIENTO FORESTAL DOMESTICO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-17
              variant="info"
              style="background: #45b39d"
              >PERMISO PARA EL APROVECHAMIENTO FORESTAL DOMESTICO</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-17"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (5)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso o autorización que otorga la CVC,
                        concediendo el derecho a aprovechar bosques naturales
                        ubicados en terrenos de dominio público o privado,
                        respectivamente.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button href="/Permisoafd" variant="outline-success">
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PERMISO PARA EL FUNCIONAMIENTO DE JARDINES BOTANICOS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-18
              variant="info"
              style="background: #45b39d"
              >PERMISO PARA EL FUNCIONAMIENTO DE JARDINES BOTÁNICOS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-18"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Jardines Botánicos_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Las colecciones de plantas vivas científicamente
                        organizadas, para tener los beneficios, estímulos y
                        prerrogativas contemplados en la Ley 299 de julio 26 de
                        1996, "Por la cual se protege la flora colombiana, se
                        reglamentan los jardines botánicos y se dictan otras
                        disposiciones".
                      </b-card-text>
                      <center>
                        <br />
                        <b-button href="/Permisofjb" variant="outline-success">
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PERMISO RECOLECCIÓN ESPECÍMENES SILVESTRES DE DIVERSIDAD BIOLÓGICA FINES DE ELABORACIÓN ESTUDIOS AMB -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-19
              variant="info"
              style="background: #45b39d"
              >PERMISO RECOLECCIÓN ESPECÍMENES SILVESTRES DE DIVERSIDAD
              BIOLÓGICA FINES DE ELABORACIÓN ESTUDIOS AMB</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-19"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (6)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso para la recolección de especímenes de
                        especies de la diversidad biológica con fines de
                        investigación científica no comercial o con fines de
                        elaboración de estudios ambientales.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Permisoresdbee"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        
        <!-- PERMISO DE APROVECHAMIENTO DE ÁRBOLES AISLADOS-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-24
              variant="info"
              style="background: #45b39d"
              >PERMISO DE APROVECHAMIENTO DE ÁRBOLES AISLADOS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-24"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (10)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso o autorización de aprovechamiento de
                        árboles aislados.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Sinriesgoapteaa"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                        <!--Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>-->
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <br />

    <p class="container" style="font-weight: bold; text-align: center">
      SOLICITUDES PRIORITARIAS - TALA DE EMERGENCIA - TALA O REUBICACIÓN POR
      OBRA PÚBLICA O PRIVADA DE ÁRBOLES AISLADOS
    </p>
    <div class="container">
      <!-- Inicio Acordeon -->
      <div class="accordion" role="tablist">
        <!-- SOLICITUDES PRIORITARIAS DE ÁRBOLES AISLADOS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-20
              variant="info"
              style="background: #45b39d"
              >SOLICITUDES PRIORITARIAS DE ÁRBOLES AISLADOS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-20"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Emisiones Atmosféricas_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es la autorización o permiso para aprovechar árboles
                        aislados de bosque natural que se encuentren caídos o
                        muertos por causas naturales, o que por razones de orden
                        sanitario requieran ser talados o ubicado en terrenos
                        públicos o privados.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Porriesgoapteaa"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- TALA DE EMERGENCIA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-46
              variant="info"
              style="background: #45b39d"
              >TALA DE EMERGENCIA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-46"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Emisiones Atmosféricas_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso o autorización para talar o podar árboles
                        aislados en centros urbanos que, por razones de su
                        ubicación, estado sanitario o daños mecánicos, estén
                        causando perjuicio a la estabilidad de los suelos,
                        canales de agua, andenes, calles, obras de
                        infraestructura o edificaciones.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Porriesgoapteaa"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- TALA O REUBICACIÓN POR OBRA PÚBLICA O PRIVADA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-47
              variant="info"
              style="background: #45b39d"
              >TALA O REUBICACIÓN POR OBRA PÚBLICA O PRIVADA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-47"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Emisiones Atmosféricas_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el permiso o autorización para talar, transplantar o
                        reubicar árboles aislados en centros urbanos, para la
                        realización, remodelación o ampliación de obras públicas
                        o privadas de infraestructura, construcciones,
                        instalaciones y similares.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioTalarpp"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutafunforestal)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <br />

    <p class="container" style="font-weight: bold; text-align: center">
      OTROS TRÁMITES AMBIENTALES
    </p>
    <div class="container" id="OTA">
      <!-- Inicio Acordeon -->
      <div class="accordion" role="tablist">
        <!-- CESIÓN  DE DERECHOS AMBIENTALES -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-25
              variant="info"
              style="background: #3498db"
              >CESIÓN DE DERECHOS AMBIENTALES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-25"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es la autorización de la cesión de un derecho ambiental
                        otorgado previamente por la Corporación.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urlCesiondr()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- CONSTITUCIÓN PARTE INTERESADA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-26
              variant="info"
              style="background: #3498db"
              >CONSTITUCIÓN PARTE INTERESADA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-26"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el reconocimiento de la CVC de una persona natural o
                        jurídica, pública o privada, para que ejerza su derecho
                        a participar en la toma de decisiones en materia
                        ambiental.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosConstitucionpi()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- CONSULTA PREVIA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-27
              variant="info"
              style="background: #3498db"
              >CONSULTA PREVIA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-27"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (10)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Derecho fundamental que tienen los grupos étnicos, de
                        poder decidir sobre medidas legislativas y
                        administrativas o proyectos, obras que se vayan a
                        realizar dentro de sus territorios, buscando de esta
                        manera proteger su integridad cultural, social y
                        económica y garantizar su participación.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosConsultap()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ACREDITACIÓN O CERTIFICACIÓN DE LAS INVERSIONES DE CONTROL Y MEJORAMIENTO DEL MEDIO AMBIENTE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-28
              variant="info"
              style="background: #3498db"
              >ACREDITACIÓN O CERTIFICACIÓN DE LAS INVERSIONES DE CONTROL Y
              MEJORAMIENTO DEL MEDIO AMBIENTE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-28"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosAcreditacioncicmma()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ACTIVACIÓN DE INSCRIPCIÓN EN EL INVENTARIO NACIONAL DE PCB 
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-29
              variant="info"
              style="background: #3498db"
              >ACTIVACIÓN DE INSCRIPCIÓN EN EL INVENTARIO NACIONAL DE
              PCB</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-29"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosActivacionpcb()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- DESINTEGRACIÓN VEHICULAR -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-30
              variant="info"
              style="background: #3498db"
              >DESINTEGRACIÓN VEHICULAR</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-30"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (10)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Certificación ambiental a entidades desintegradoras en
                        cumplimiento con las condiciones y requisitos
                        ambientales que permiten desarrollar el proceso de
                        desintegración de vehículos automotores.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosdesintegracionv()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- EXPEDICIÓN CONCEPTOS TÉCNICOS RELACIONADOS CON AGUAS SUBTERRÁNEAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-31
              variant="info"
              style="background: #3498db"
              >EXPEDICIÓN CONCEPTOS TÉCNICOS RELACIONADOS CON AGUAS
              SUBTERRÁNEAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-31"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es la emisión de un concepto técnico relacionado con las
                        aguas subterráneas.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosExpedicionctras()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- EXPEDICIÓN DE LA CERTIFICACIÓN DE EQUIPOS EN MATERIA DE REVISIÓN DE GASES PARA CENTROS DE DIAGNÓSTICO AUTOMOTOR -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-32
              variant="info"
              style="background: #3498db"
              >EXPEDICIÓN DE LA CERTIFICACIÓN DE EQUIPOS EN MATERIA DE REVISIÓN
              DE GASES PARA CENTROS DE DIAGNÓSTICO AUTOMOTOR</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-32"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosExpedicioncemrgcda()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PLANES DE CONTINGENCIA PARA MANEJO DE DERRAMES DE HIDROCARBUROS O SUSTANCIAS NOCIVAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-36
              variant="info"
              style="background: #3498db"
              >PLANES DE CONTINGENCIA PARA MANEJO DE DERRAMES DE HIDROCARBUROS O
              SUSTANCIAS NOCIVAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-36"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (10)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosPlanescmdhsn()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PLAN DE CONTINGENCIA PARA EL TRANSPORTE DE HIDROCARBUROS, DERIVADOS O SUSTANCIAS NOCIVAS Y RADICACION DEL REPORTE ANUAL DE ESTE PLAN -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-51
              variant="info"
              style="background: #3498db"
              >PLAN DE CONTINGENCIA PARA EL TRANSPORTE DE HIDROCARBUROS, DERIVADOS O SUSTANCIAS NOCIVAS Y RADICACION DEL REPORTE ANUAL DE ESTE PLAN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-51"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/pct.png"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Toda persona natural o jurídica que realiza actividades de transporte de hidrocarburos, derivados y sustancias nocivas no sujetas a licenciamiento ambiental, debe radicar el plan de contingencias para el manejo de derrames en el desarrollo de dicha actividad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                        href="/FormularioPlanchsn"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato de la solicitud.
                        <br />
                        <b-dropdown
                          variant="outline-primary"
                          text="Formato"
                        >
                          <b-dropdown-item
                            @click="
                              abrirNuevoTab(rutas.rutaFormatoPlanContingenciaTranspote)
                            "
                            ><b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            Formato Adjunto</b-dropdown-item
                          ></b-dropdown
                        >
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PLANES DE SANEAMIENTO Y MANEJO DE VERTIMIENTOS - PSMV -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-37
              variant="info"
              style="background: #3498db"
              >PLANES DE SANEAMIENTO Y MANEJO DE VERTIMIENTOS - PSMV</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-37"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Trámite para recibir aprobación de los Planes de
                        Saneamiento y Manejo de Vertimientos – PMSV presentados
                        por los prestadores del servicio de alcantarillado y
                        actividades complementarias, de acuerdo a la
                        normatividad vigente.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosPlanessmv()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PROGRAMA PARA EL USO EFICIENTE Y AHORRO DEL AGUA - PUEAA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-38
              variant="info"
              style="background: #3498db"
              >PROGRAMA PARA EL USO EFICIENTE Y AHORRO DEL AGUA -
              PUEAA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-38"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Herramienta de planificación y administración enfocada a
                        la optimización del uso del recurso hídrico, conformado
                        por el conjunto de proyectos y acciones que le
                        corresponde adoptar a los usuarios que soliciten
                        concesión de aguas, con el propósito de contribuir a la
                        sostenibilidad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosProgramaueha()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PLAN DE CUMPLIMIENTO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-39
              variant="info"
              style="background: #3498db"
              >PLAN DE CUMPLIMIENTO</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-39"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (10)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el instrumento mediante el cual el interesado
                        presenta a la CVC, los proyectos, obras, actividades y
                        buenas prácticas, que garanticen el cumplimiento de la
                        norma de vertimientos y que incluye las metas, sus
                        periodos de evaluación y sus indicadores de seguimiento,
                        gestión y resultados.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          v-on:click="urldatosPlanCumplimiento()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- RAMA JUDICIAL -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-40
              variant="info"
              style="background: #3498db"
              >RAMA JUDICIAL NOTIFICACIONES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-40"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el trámite que adelanta un despacho judicial para dar
                        a conocer a la CVC de una actuación procesal.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosRamajn()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- TRASPASO, AUMENTO, REBAJA, CANCELACION, UNIFICACIÓN CAMBIO DE USO O CAMBIO DE SITIO DE CAPTACIÓN-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-41
              variant="info"
              style="background: #3498db"
              >TRASPASO, AUMENTO, REBAJA, CANCELACIÓN, UNIFICACIÓN CAMBIO DE USO
              O CAMBIO DE SITIO DE CAPTACIÓN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-41"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        El traspaso total o parcial de una concesión de aguas,
                        corresponde a la cesión del derecho ambiental en los
                        eventos que se produzca la tradición del predio
                        beneficiario con una concesión.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatostrasaumrebcanuni()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- REGISTRO DE ESTABLECIMIENTOS DEDICADOS A LA EXPLOTACIÓN DE RECURSOS DE FLORA Y FAUNA 
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-21
              variant="info"
              style="background: #3498db"
              >REGISTRO DE ESTABLECIMIENTOS DEDICADOS A LA EXPLOTACIÓN DE
              RECURSOS DE FLORA Y FAUNA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-21"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el registro de las empresas dedicadas a la
                        transformación primaria, transformación secundaria,
                        comercialización y transformación secundaria y las
                        integradas de productos forestales, de los viveros
                        forestales, criaderos de flora silvestre, huertos o
                        rodales semilleros.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Regisrtroederff"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- REGISTRO DE PLANTACIONES PROTECTORAS, PROTECTORAS - PRODUCTORAS 
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-22
              variant="info"
              style="background: #3498db"
              >REGISTRO DE PLANTACIONES PROTECTORAS, PROTECTORAS -
              PRODUCTORAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-22"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (9)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el acto administrativo motivado por el cual se
                        procede a registrar las plantaciones forestales
                        protectoras, productoras y protectoras.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button href="/Regisrtropp" variant="outline-success">
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoPlantaciones)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- REGISTRO DEL LIBRO DE OPERACIONES DE INDUSTRIAS O EMPRESAS FORESTALES
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-23
              variant="info"
              style="background: #3498db"
              >REGISTRO DEL LIBRO DE OPERACIONES DE INDUSTRIAS O EMPRESAS
              FORESTALES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-23"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el acto administrativo motivado por el cual se
                        procede a registrar el libro de operaciones de las
                        industrias o empresas forestales.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Regisrtroloief"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- REGISTRO NEGOCIO VERDE
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-48
              variant="info"
              style="background: #3498db"
              >REGISTRO NEGOCIO VERDE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-48"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Los negocios verdes son las actividades económicas en
                        las que se ofertan bienes o servicios, que generan
                        impactos ambientales positivos y además incorporan
                        buenas prácticas ambientales, sociales y económicos con
                        enfoque de ciclo de vida.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                        href="/FormularioNverde"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
      </div>
    </div>
    <br />
    <!--<p class="container" style="font-weight: bold; text-align: center">
      REGISTROS AMBIENTALES
    </p>
    <div class="container" id="RA">-->
      <!-- Inicio Acordeon 
      <div class="accordion" role="tablist">-->
        <!-- INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA MANUFACTURERO
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-33
              variant="info"
              style="background: #45b39d"
              >INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA
              MANUFACTURERO</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-33"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/rua.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el instrumento de captura para el Subsistema de
                        Información Sobre Uso de Recursos Naturales Renovables,
                        SIUR. En el cual se obtiene información estandarizada
                        sobre el uso, aprovechamiento o afectación de los
                        recursos naturales renovables por las actividades del
                        sector manufacturero.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRUA"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                   <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoRua)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        >
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!--  INSCRIPCIÓN O ACTUALIZACIÓN DEL DEPARTAMENTO DE GESTIÓN AMBIENTAL-DGA
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-45
              variant="info"
              style="background: #45b39d"
              >INSCRIPCIÓN O ACTUALIZACIÓN DEL DEPARTAMENTO DE GESTIÓN
              AMBIENTAL-DGA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-45"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/DGA1.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Área de gestión ambiental de empresas a nivel
                        industrial; responsable de velar por el cumplimiento de
                        la normatividad ambiental; prevenir, minimizar y
                        controlar la generación de cargas contaminantes;
                        prácticas de producción más limpia, uso racional de los
                        recursos naturales; uso de combustible limpios; proteger
                        y conservar los ecosistemas.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioDGA"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- INSCRIPCIÓN EN EL REGISTRO DE GENERADORES DE RESIDUOS O DESECHOS PELIGROSOS – RESPEL
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-35
              variant="info"
              style="background: #45b39d"
              >INSCRIPCIÓN EN EL REGISTRO DE GENERADORES DE RESIDUOS O DESECHOS
              PELIGROSOS – RESPEL</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-35"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/respel.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Instrumento de gestión de captura información homogénea
                        y sistemática sobre generación de residuos o desechos
                        peligrosos, originados por actividades productivas y
                        sectoriales del país, lo cual contribuirá a mejorar el
                        conocimiento de la problemática, la planificación de su
                        gestión y el establecimiento de acciones asociada a este
                        tipo de residuos.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRespel"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoRespel)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        >
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card> -->
        <!--  INSCRIPCIÓN DE GENERADOR INDUSTRIAL, COMERCIAL O DE SERVICIOS DE ACEITES DE COCINA USADOS - ACU
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-42
              variant="info"
              style="background: #45b39d"
            >
              INSCRIPCIÓN DE GENERADOR INDUSTRIAL, COMERCIAL O DE SERVICIOS DE
              ACEITES DE COCINA USADOS - ACU</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-42"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/ACU_GENERADOR.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Toda persona industrial, comercial y de servicios que
                        genere ACU deberán inscribirse ante la Autoridad
                        Ambiental competente en el área donde se realizará la
                        actividad de generación. Información que se utiliza de
                        base para ejercer el control y verificación de
                        cumplimiento de las obligaciones establecidas en la
                        normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioACU"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!--  FORMULARIO REPORTE ANUAL DE ACEITA DE COCINA USADO - ACU PARA GESTORES
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-43
              variant="info"
              style="background: #45b39d"
            >
              REPORTE ANUAL DE ACEITE DE COCINA USADO - ACU PARA
              GESTORES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-43"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/REPORTE ANUAL.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        El reporte anual para gestores de ACU, debe realizarse
                        hasta el 15 de enero de cada año, de vital importancia
                        en marco del cumplimiento de la normativa. Información
                        que se utiliza de base para ejercer el control y
                        verificación de cumplimiento de las obligaciones
                        establecidas en la normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRAACU"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato a Diligenciar.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaReporteanual)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!--  FORMULARIO SOLICITUD DE INSCRIPCIÓN PARA GESTOR DE ACEITE DE COCINA USADO - ACU
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-44
              variant="info"
              style="background: #45b39d"
            >
              SOLICITUD DE INSCRIPCIÓN PARA GESTOR DE ACEITE DE COCINA USADO -
              ACU</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-44"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/ACU_GESTOR.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Toda persona que sea gestor de ACU deberá inscribirse
                        ante la Autoridad Ambiental competente en el área donde
                        se realice la actividad de recolección, tratamiento y/o
                        aprovechamiento de ACU. Información que se utiliza de
                        base para ejercer el control y verificación de
                        cumplimiento de las obligaciones establecidas en la
                        normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioGestorACU"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- ANEXO IV. INSCRIPCIÓN DE LAS EMPRESAS TRANSFORMADORAS ANTE LA AUTORIDAD AMBIENTAL COMPETENTE 
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-34
              variant="info"
              style="background: #45b39d"
              >ANEXO IV. INSCRIPCIÓN DE LAS EMPRESAS TRANSFORMADORAS ANTE LA
              AUTORIDAD AMBIENTAL COMPETENTE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-34"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/dga.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Empresas transformadoras deben cumplir con los
                        requisitos técnicos y legales para certificar las
                        toneladas aprovechadas al Plan de Gestión ambiental de
                        Residuos de Envases y Empaques, la medición de la
                        eficiencia de retornabilidad que permite identificar la
                        cantidad de envases y empaques retornables, que, no
                        ingresan al circuito de retorno del productor.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioITE"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                   <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoIET)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        >
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
        <!-- ANEXO IV. FORMATO PARA LA INSCRIPCIÓN DE GESTORES DE LLANTAS USADAS ANTE LA AUTORIDAD AMBIENTAL COMPETENTE 
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-49
              variant="info"
              style="background: #45b39d"
              >ANEXO IV. FORMULARIO PARA LA INSCRIPCIÓN DE GESTORES DE 
LLANTAS USADAS ANTE LA AUTORIDAD AMBIENTAL COMPETENTE </b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-49"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/llantas.png"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                      Toda Persona natural o jurídica que realiza actividades de reencauche, almacenamiento y aprovechamiento de llantas usadas, debe Inscribirse ante la autoridad ambiental competente en el área donde desarrolla sus actividades, según lo dispuesto en el formato del anexo IV de la presente resolución.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioILLANTAS"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>--> 
        <!-- ANEXO IV. FORMATO PARA LA INSCRIPCIÓN DE GESTORES DE RCD ANTE LA AUTORIDAD AMBIENTAL COMPETENTE  
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-50
              variant="info"
              style="background: #45b39d"
              >ANEXO IV. FORMATO PARA LA INSCRIPCIÓN DE GESTORES DE RCD ANTE LA AUTORIDAD AMBIENTAL COMPETENTE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-50"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/RCD.png"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                      Toda persona natural o jurídica, pública o privada que desarrolle actividades de recolección, transporte, almacenamiento, aprovechamiento y/o disposición final de Residuos de Construcción y Demolición - RCD, debe inscribirse ante la autoridad ambiental competente. Información que se utiliza con fines de control y seguimiento al cumplimiento de las obligaciones establecidas en la normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRCD"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>-->
    <br />
  </div>
</template>

<script>
import {
  datosCesiondr,
  datosConstitucionpi,
  datosConsultap,
  datosAcreditacioncicmma,
  datosActivacionpcb,
  datosdesintegracionv,
  datosExpedicionctras,
  datosExpedicioncemrgcda,
  datosInscripcionrurm,
  datosInscripciondgae,
  datosInscripcionrespel,
  datosPlanescmdhsn,
  datosPlanessmv,
  datosProgramaueha,
  datosPlanCumplimiento,
  datosRamajn,
  datostrasaumrebcanuni,
  PLAN_DE_CONTINGENCIA_PARA_EL_TRANSPORTE_DE_HIDROCARBUROS
} from "../properties";
export default {
  data() {
    return {
      show: true,
      selected: null,
      rutas: {
        rutaformatoSuperficiales:
          "../../document/FT.0350.17 FUN de solicitud de concesión de aguas superficiales V03.pdf",
        rutaAnexoSuperficales:
          "../../document/Anexos Concesiones de Aguas Superficiales.pdf",
        rutaformatoSubterraneas:
          "../../document/FT.0350.18 FUN de solicitud de concesión de aguas subterráneas V03.pdf",
        rutaAnexoSubterraneas:
          "../../document/Anexos Concesiones de Aguas Subterráneas.pdf",
        rutaformatoVertimientoagua:
          "../../document/FT.0350.51 FUN de permiso de vertimiento a cuerpos de agua V01.pdf",
        rutaAnexoVertimientoagua:
          "../../document/Anexos Vertimientos al Agua.pdf",
        rutaformatoVertimientosuelo:
          "../../document/02-11-2021-anla-funpvsuelo.pdf",
        rutaAnexoVertimientosuelo:
          "../../document/Anexos Vertimientos al Suelo.pdf",
        rutaformatoAdecuacionterrenos:
          "../../document/FT.0350.07 V02 20201221 Solicitud de autorizacion para adecuacionde terrenos.doc",
        rutaformatoViascarreteables:
          "../../document/FT.0350.08 V04 20240320 Solicitud apertura de vias, carreteables y explanaciones.doc",
        rutaformatoAprovechamietoforestal:
          "../../document/FT.0350.09 Formulario de solicitud de aprovechamiento forestal arboles aislados.doc",
        rutaformatoOcupacioncause:
          "../../document/FT.0350.21 FUN  solicitud de Ocupacion de Cauce, playas y lechos.pdf",
        rutaformatoemisionesafmos:
          "../../document/FT.0350.15 FUN  solicitud de permiso de emisiones atmosfericas fuentes fijas.doc",
        rutapueaasimplificado:
          "../../document/FT.0350.53 V02 20231227 Formulario para la presentacion del programa PUEAA Simplificado.xlsx",
        rutapueaacomun:
          "../../document/FT.0350.54 V02 20231227 Formulario para la presentacion del programa PUEAA Comun.xlsx",
        rutafunforestal: "../../document/fun forestal.pdf",
        rutaOtorgamiento:
          "../../document/anexo_1_formulario_unico_solicitud_o_modificacion_licencia_ambiental.xlsx",
        rutaReporteanual:
          "../../document/FT.0340.66 V01 20221130 Reporte anual  de aceite de cocina usado - ACU - Para gestores.xlsx",
        rutaFormatoIET: "../../document/ANEXO IV INS_TRNASFORMADORAS.pdf",
        rutaFormatoRua:
          "../../document/ANEXO 2 INSCRIPCIÓN RUA_Resolucion 1023 de 2010.pdf",
        rutaFormatoRespel:
          "../../document/Formato Solicitud Inscripcion Registro Respel.pdf",
        rutaFormatoDGA:
          "../../document/FT.0340.20 V03 20221012 Inscripcion o actualizacion del departamento de gestion ambiental.pdf",
        rutaFormatoPlantaciones:
          "../../document/FUN-Registro-plantaciones-forestales-protectoras-y-o-pro.pdf",
          rutacircularpueaa:
          "../../document/Circular No 0006 - Orientaciones frente a presentacion de PUEAA.pdf",
          rutaFormatoPlanContingenciaTranspote:"../../document/FormatoPlanContigenciaTransporteHidrocarburos.pdf",
          rutaorientacionpueaa:"../../document/102pueaa.pdf",
      },
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
  },
  methods: {
    abrirNuevoTab(param) {
      // Abrir nuevo tab
      var url = param;
      var win = window.open(url, "_blank");
      // Cambiar el foco al nuevo tab (punto opcional)
      win.focus();
    },
    urlCesiondr() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosCesiondr,
      });
    },
    urldatosConstitucionpi() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosConstitucionpi,
      });
    },
    urldatosConsultap() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosConsultap,
      });
    },
    urldatosAcreditacioncicmma() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosAcreditacioncicmma,
      });
    },
    urldatosActivacionpcb() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosActivacionpcb,
      });
    },
    urldatosdesintegracionv() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosdesintegracionv,
      });
    },
    urldatosExpedicionctras() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosExpedicionctras,
      });
    },
    urldatosExpedicioncemrgcda() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosExpedicioncemrgcda,
      });
    },
    urldatosInscripcionrurm() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosInscripcionrurm,
      });
    },
    urldatosInscripciondgae() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosInscripciondgae,
      });
    },
    urldatosInscripcionrespel() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosInscripcionrespel,
      });
    },
    urldatosPlanescmdhsn() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosPlanescmdhsn,
      });
    },
    urldatosPlancth() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: PLAN_DE_CONTINGENCIA_PARA_EL_TRANSPORTE_DE_HIDROCARBUROS,
      });
    },
    urldatosPlanessmv() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosPlanessmv,
      });
    },
    urldatosProgramaueha() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosProgramaueha,
      });
    },
    urldatosPlanCumplimiento() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosPlanCumplimiento,
      });
    },
    urldatosRamajn() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datosRamajn,
      });
    },
    urldatostrasaumrebcanuni() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "Generico",
        params: datostrasaumrebcanuni,
      });
    },
  },
  components: {},
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #000000;
}
</style>
